import * as Herz from '@rushplay/herz'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import * as ThemeUi from 'theme-ui'

export function NotFound() {
  const translate = Herz.I18n.useTranslate()

  return (
    <ThemeUi.Box
      sx={{
        backgroundImage:
          'url(/images/background-pattern-d-shape.svg), linear-gradient(180deg, rgba(0, 87, 255, 0) 0%, #17061D 100%), linear-gradient(180deg, #015b9a 0%, rgba(23, 6, 29, 0) 100%)',
        backgroundSize: '5000px, cover, cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        flex: 1,
        width: '100%',
        py: [2, 4],
        px: 1,
      }}
    >
      <ThemeUi.Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
          mx: 'auto',
          maxWidth: '1000px',
          width: '100%',
          p: 2,
          backgroundColor: 'background',
          borderRadius: 1,
          gap: 2,
          textAlign: 'center',
        }}
      >
        <ThemeUi.Heading
          as="h1"
          sx={{
            fontSize: '46px',
          }}
        >
          404
        </ThemeUi.Heading>
        <ThemeUi.Text>{translate('not-found.content')}</ThemeUi.Text>
        <ThemeUi.Link as={ReactRouterDom.Link} to="/" variant="footerNav">
          {translate('not-found.link')}
        </ThemeUi.Link>
      </ThemeUi.Box>
    </ThemeUi.Box>
  )
}

// For @loadable/components
export default NotFound

Herz.I18n.Loader.preload(['not-found.content', 'not-found.link'], NotFound)
